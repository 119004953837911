import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'ratingStars',
})
export class RatingStarsPipe implements PipeTransform {
	transform(rating: number): string {
		const wholeNumber = Math.floor(rating)
		const fractionalPart = rating % 1
		const totalStars = 5

		let filledStars = ''
		for (let i = 0; i < wholeNumber; i++) {
			filledStars += '★'
		}

		const remainingStars = Math.round(fractionalPart * totalStars)
		for (let i = 0; i < remainingStars; i++) {
			filledStars += '★'
		}

		const emptyStars = totalStars - filledStars.length
		for (let i = 0; i < emptyStars; i++) {
			filledStars += '☆'
		}

		return filledStars
	}
}
