<div class="custom-table overflow-x-auto">
	<table class="mat-elevation-z8 bg-transparent w-full relative">
		<thead class="bg-light">
			<tr>
				<td
					*ngFor="let col of tableConfig?.columns; let i = index"
					class="text-font-14 leading-[22px] text-medium-gray bg-off-white py-[14px] px-[30px] max-mobile:px-padding-mobile-14 whitespace-nowrap"
				>
					{{
						col != 'actions'
							? (tableConfig.prefix + '.' + col.toUpperCase() | translate)
							: ''
					}}
				</td>
			</tr>
		</thead>
		<tbody class="bg-grey-background">
			<tr
				*ngFor="
					let element of tableConfig.data
						| paginate
							: {
									itemsPerPage: tableConfig?.paging?.pageSize,
									currentPage: tableConfig?.paging?.pageNumber,
									totalItems: tableConfig?.totalCount
							  };
					let indx = index
				"
				class="{{
					indx + 1 != tableConfig.data?.length
						? 'border-b-[1px] border-secondry-color-Gray'
						: ''
				}}"
			>
				<td
					*ngFor="let col of tableConfig?.columns; let i = index"
					class="text-font-16 leading-[22px] text-medium-gray bg-off-white py-[14px] px-[30px] max-mobile:px-padding-mobile-14 whitespace-nowrap"
				>
					<span *ngIf="tableConfig.types[i] == 'ID'">
						#{{ element[col] }}
					</span>
					<span
						*ngIf="tableConfig.types[i] == 'group'"
						class="flex items-center rtl:max-mobile:ml-8 ltr:max-mobile:mr-8"
					>
						<img
							*ngIf="tableConfig?.groupImage"
							[src]="imagePath + element[tableConfig.groupImage]"
							alt=""
							class="w-[56px] h-[56px] rtl:ml-2 ltr:mr-2 max-mobile:w-[40px] max-mobile:h-[40px]"
						/>
						<span>
							<span class="block whitespace-normal">
								<img
									*ngIf="
										tableConfig?.notCompletedData &&
										!element[tableConfig.notCompletedData]
									"
									alt=""
									class="inline-block w-4 align-text-top"
									src="assets/images/danger.svg"
								/>
								{{ element[col] | slice : 0 : 15 }}
								{{ element[col]?.length > 15 ? '...' : '' }}
							</span>
							<span
								*ngIf="
									tableConfig?.groupSubTitle &&
									tableConfig?.groupSubtitleType == 'number'
								"
								class="text-medium-gray"
							>
								{{ element[tableConfig.groupSubTitle] }}
								{{
									tableConfig.prefix +
										'.' +
										tableConfig.groupSubtitleText.toUpperCase() +
										(element[col] > 1 ? 's' : '') | translate
								}}
							</span>
						</span>
					</span>
					<span *ngIf="tableConfig.types[i] == 'fixedText'">
						{{ element[col] ? element[col] : '-' }}
					</span>
					<span *ngIf="tableConfig.types[i] == 'object'">
						{{
							translate.currentLang === 'ar'
								? element[col]?.name?.ar
								: element[col]?.name?.en
						}}
					</span>
					<span
						*ngIf="tableConfig.types[i] == 'Date'"
						class="whitespace-nowrap"
					>
						{{ element[col] | date : 'dd / MM / yyy' }}
					</span>
					<span
						*ngIf="tableConfig.types[i] == 'DateTime'"
						class="whitespace-nowrap"
					>
						{{ element[col] | date : 'dd / MM / yyy' }} <br />
						<small>{{ element[col] | date : 'shortTime' }}</small>
					</span>
					<app-status
						*ngIf="tableConfig.types[i] == 'status'"
						[enumData]="tableConfig.statusEnum"
						[statusValue]="element[col]"
					></app-status>
					<span
						*ngIf="tableConfig.types[i] == 'actions'"
						class="flex justify-end"
					>
						<span
							*ngFor="let action of tableConfig.actions"
							class="mr-[18px] inline-block max-mobile:w-[20px] w-[35px]"
						>
							<img
								(click)="emitAction.emit({ action: action, obj: element })"
								[src]="'../../../../assets/images/' + action + '.svg'"
								alt=""
								class="inline-block cursor-pointer"
							/>
						</span>
					</span>
				</td>
			</tr>
		</tbody>
	</table>
	<app-no-data-found
		*ngIf="!tableConfig.data?.length"
		[text]="noDataFoundMessage"
	></app-no-data-found>
</div>
