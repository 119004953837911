import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-custom-mat-calendar',
	templateUrl: './custom-mat-calendar.component.html',
	styleUrls: ['./custom-mat-calendar.component.scss'],
})
export class CustomMatCalendarComponent implements OnInit {
	selected = new Date()
	flag: boolean = true
	minDate = new Date()
	maxDate = null
	constructor() {}

	ngOnInit(): void {}
}
