<div class="flex">
	<div
		class="ml-5 pb-5 ltr:pl-12px rtl:pl-12px ltr:border-l-[1px] rtl:border-r-[1px] border-secondry-color-Gray text-font-16 leading-6 text-medium-gray"
	>
		<ul>
			<li
				(click)="tapChanged.emit(i + 1)"
				*ngFor="let step of steps; let i = index"
				class="mb-12px {{ actionTap == i + 1 ? 'active' : '' }} cursor-pointer"
			>
				{{ step | translate }}
			</li>
		</ul>
	</div>
</div>
