<mat-form-field
	appearance="outline"
	class="w-full h-[56px] mb-[12px]"
	(click)="datepicker.open()"
>
	<mat-label>{{ label | translate }}</mat-label>
	<input
		[dir]="translate.currentLang == 'en' ? 'ltr' : 'rtl'"
		matInput
		[matDatepicker]="datepicker"
		[formControl]="date"
		(dateChange)="dateValue.emit(date.value)"
		[readonly]="true"
		[max]="startDateMax ? today : null"
		[min]="startDateMin ? today : null"
	/>
	<img
		(click)="date.setValue(null); dateValue.emit(date.value)"
		*ngIf="date?.value"
		src="./assets/images/close-square.svg"
		class="cursor-pointer absolute ltr:right-3 rtl:left-3 top-[15px]"
	/>
	<mat-datepicker #datepicker> </mat-datepicker>
</mat-form-field>
<!-- 
<mat-form-field appearance="outline">
	<mat-label>Enter a date range</mat-label>
	<mat-date-range-input [rangePicker]="rangePicker">
		<input matStartDate placeholder="Start date" />
		<input matEndDate placeholder="End date" />
	</mat-date-range-input>
	<mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
	<mat-date-range-picker #rangePicker>
		<mat-date-range-picker-actions>
			<button class="text-medium-gray" mat-button matDateRangePickerCancel>
				Cancel
			</button>
			<button class="ok" mat-button matDateRangePickerApply>Ok</button>
		</mat-date-range-picker-actions>
	</mat-date-range-picker>
</mat-form-field> -->
