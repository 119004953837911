<div class="fixed inline-flex bottom-2 rtl:left-2 ltr:right-8 flex-wrap z-10">
	<button
		[disabled]="disabled"
		class="bg-gradient {{
			disabled ? 'opacity-70' : ''
		}} items-center flex z-10 text-[#FCFCFC] rounded-[16px] h-[56px] m-[8px] px-3"
		(click)="clickEvent.emit()"
		[style]="'width:' + buttonWidth + 'px'"
		[ngClass]="{ custom: isScrolling && !isScrollingTop }"
	>
		<img
			[ngClass]="{ centerImage: isScrolling && !isScrollingTop }"
			class="w-7"
			src="assets/images/{{ imgPath }}"
			alt=""
		/>
		<span
			[ngClass]="{ hideSpan: isScrolling && !isScrollingTop }"
			class="text-[16px] whitespace-nowrap"
			[style]="'width:' + (buttonWidth - 24) + 'px'"
			>{{ buttonTitle | translate }}</span
		>
	</button>
	<button
		*ngIf="button2Title"
		class="bg-gradient items-center flex z-10 text-[#FCFCFC] rounded-[16px] h-[56px] m-[8px] px-3"
		(click)="click2Event.emit()"
		[style]="'width:' + button2Width + 'px'"
		[ngClass]="{ custom: isScrolling && !isScrollingTop }"
	>
		<img
			[ngClass]="{ centerImage: isScrolling && !isScrollingTop }"
			class="w-7"
			src="assets/images/{{ img2Path }}"
			alt=""
		/>
		<span
			[ngClass]="{ hideSpan: isScrolling && !isScrollingTop }"
			class="text-[16px] whitespace-nowrap"
			[style]="'width:' + (button2Width - 24) + 'px'"
			>{{ button2Title | translate }}</span
		>
	</button>
</div>
