<form
	class="max-w-screen-md  bg-white rounded-lg py-[36px] px-[48px] max-mobile:py-[7px] max-mobile:px-[12px] {{data.fullWidth? 'w-full':''}}"
>
	<div (click)="closeDialog()">
		<img
			class="ml-[100%] cursor-pointer"
			src="assets/images/close-square.svg"
			alt=""
		/>
	</div>
	<div class="text-center">
		<img
			*ngIf="data?.image"
			class="m-auto h-auto w-auto my-[18px] max-mobile:my-[8px]"
			[src]="data.image"
			alt="done"
		/>
		<h1
			class=""
			[ngClass]="!data?.titleStyle ? 'm-0 text-gray-500' :  data?.titleStyle"
		>
			{{data.title | translate}}
		</h1>
	</div>
	<div class="my-2 text-center text-dark-grey">
		<p
			[ngClass]="data?.flex ? 'mb-24px' : (data?.msgStyle ? data?.msgStyle : ' ltr:font-DIN-bold font-bold rtl:font-semibold w-100 h-[30px] text-lg max-mobile:text-font-mobile-small max-mobile:inline-block')"
		>
			{{data.message | translate}}
		</p>
	</div>
	<div *ngIf="data.hasInput" class="pt-3" [formGroup]="formData">
		<mat-form-field class="w-full relative mb-[12px]" appearance="outline">
			<mat-label class="text-dark-grey">{{ data.label | translate }}</mat-label>
			<input
				required
				class="text-font-16 text-[#4E4B66]"
				type="text"
				matInput
				[(ngModel)]="formControl"
				formControlName="formControl"
			/>
			<img
				*ngIf="getController('formControl')?.value"
				(click)="getController('formControl')?.reset()"
				class="cursor-pointer absolute ltr:right-0 rtl:left-0 inline-block top-[9px]"
				src="./assets/images/close-square.svg"
				alt=""
			/>
			<mat-error>
				<validation-message
					[controller]="getController('formControl')"
					controller_name="{{data.label}}"
					pattern_Message="VALIDATION.NAME"
				>
				</validation-message>
			</mat-error>
		</mat-form-field>
	</div>
	<div *ngIf="data?.flex" class="flex">
		<button
			[ngClass]="data?.cancelBtnStyle  ? data?.cancelBtnStyle  : 'text-gray-800 border border-solid border-[#D9DBE9]'"
			*ngIf="data.cancelBtn"
			(click)="closeDialog()"
			class="w-full mt-3 mr-3 rtl:ml-3"
			mat-button
		>
			{{data.cancelBtn | translate}}
		</button>
		<button
			[ngClass]="data?.confirmBtnStyle  ? data?.confirmBtnStyle  : 'text-white bg-gradient'"
			(click)="confirm()"
			class="w-full mt-3"
			mat-button
		>
			{{data.confirmBtn | translate}}
		</button>
	</div>
	<div *ngIf="data?.notflex" class="text-center">
		<button
			[disabled]="formData.invalid && data?.hasInput"
			[ngClass]="data?.confirmBtnStyle  ? data?.confirmBtnStyle  : 'bg-gradient text-white'"
			class="w-full mt-3"
			(click)="confirm()"
			mat-button
		>
			{{data.confirmBtn | translate}}
		</button>
		<button
			[ngClass]="data?.cancelBtnStyle  ? data?.cancelBtnStyle  : 'text-medium-gray bg-transparent'"
			*ngIf="data.cancelBtn"
			(click)="closeDialog()"
			class="w-full mt-3"
			mat-button
		>
			{{data.cancelBtn | translate}}
		</button>
	</div>
</form>
