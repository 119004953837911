<div class="container mx-auto">
	<!-- <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-3"> -->
	<div
		class="flex flex-col justify-center border-2 border-[#D9DBE9] rounded-[12px] p-0 text-center max-w-[296px] h-[193px] content"
	>
		<img
			src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"
			class="w-[56px] h-[56px] my-12px rounded-full mx-auto"
			alt=""
		/>
		<p class="font-Poppins font-16 text-dark-grey mb-[3px]">client name</p>
		<p class="font-Poppins font-16 text-dark-grey mb-12px">
			+069 1221 0000 000
		</p>
		<button
			class="text-dark-grey rounded-[6px] h-[42px] m-[8px] border border-solid border-[#D9DBE9]"
			mat-button
		>
			{{ 'ACTIONS.VEIW' | translate }}
		</button>
	</div>
	<!-- </div> -->
</div>
