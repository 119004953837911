import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { workingHour } from '../../models/general.models'
import { weekDays } from '../../enums/branch-types.enum'
import { TranslateService } from '@ngx-translate/core'
import { OrganizationsService } from 'src/app/modules/organization/services/organizations.service'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-working-hours',
	templateUrl: './working-hours.component.html',
	styleUrls: ['./working-hours.component.scss'],
})
export class WorkingHoursComponent implements OnInit, OnChanges {
	@Input() branchId: any
	@Input() workingHours: workingHour[] = []
	@Input() editMode: boolean = false
	@Output() dataChanged: EventEmitter<any> = new EventEmitter()
	@Output() inValid: EventEmitter<any> = new EventEmitter()
	workingHoursForm = new FormGroup({ workingHours: new FormArray([]) })
	weekDays = weekDays
	subscription = new Subscription()
	showError: string[] = []
	showOffHours: any[] = []
	showValidationError: boolean[] = []
	constructor(
		private translate: TranslateService,
		private orgServ: OrganizationsService
	) { }

	ngOnInit(): void {
		this.initForm()
	}

	ngOnChanges($event: any) {
		this.editMode
			? this.getFormArray().forEach((form) => form.enable())
			: this.getFormArray().forEach((form) => form.disable())
		$event['workingHours'] ? this.initForm() : null
	}

	initForm() {
		(this.workingHoursForm.get('workingHours') as FormArray).clear()
		this.workingHours.forEach((obj) => {
			(this.workingHoursForm.get('workingHours') as FormArray).push(
				new FormGroup({
					id: new FormControl(obj.id || null),
					dayOfWeek: new FormControl(obj.dayOfWeek || 0, Validators.required),
					from: new FormControl(
						obj?.from.slice(0, 5) || '',
						Validators.required
					),
					to: new FormControl(obj?.to.slice(0, 5) || '', Validators.required),
					offTimeFrom: new FormControl(obj?.offTimeFrom?.slice(0, 5) || '', Validators.required),
					offTimeTo: new FormControl(obj?.offTimeTo?.slice(0, 5) || '', Validators.required),
					isOn: new FormControl(obj.isOn || false, Validators.required),
				})
			)
			this.showOffHours.push(obj?.offTimeFrom)
		})
		this.editMode
			? this.getFormArray().forEach((form) => form.enable())
			: this.getFormArray().forEach((form) => form.disable())

		this.workingHoursForm.valueChanges.subscribe((val) => {
			const newVal = JSON.parse(JSON.stringify(val))
			newVal.workingHours.forEach((element: workingHour, i: number) => {
				element.from = this.convertTime(element.from)
				element.to = this.convertTime(element.to)
				element.offTimeFrom ? element.offTimeFrom = this.convertTime(element.offTimeFrom) : null
				element.offTimeTo ? element.offTimeTo = this.convertTime(element.offTimeTo) : null
			})
			this.dataChanged.emit(newVal)
		})
	}

	convertTime(time: any) {
		let newTime = new Date()
		newTime.setHours(this.setHour(time))
		newTime.setMinutes(+time?.split(':')[1]?.split(' ')[0])
		newTime.setSeconds(0)
		return newTime.toTimeString()?.split(' ')[0]
	}

	deleteOffTime(form: FormGroup, i: number) {
		this.showOffHours[i] = false
		form.get('offTimeFrom')?.setValue(null);
		form.get('offTimeTo')?.setValue(null)
		this.validateWorkingHoures(form,i)
	}

	setHour(time: any) {
		if (time?.includes('PM') || time?.includes('م')) {
			return +time?.split(':')[0] == 12 ? 12 : +time?.split(':')[0] + 12
		}
		else if (time?.includes('AM') || time?.includes('ص')) {
			return +time?.split(':')[0] == 12 ? 0 : +time?.split(':')[0]
		}
		else return +time?.split(':')[0]
	}


	getMin(event: any) {
		if (event) {
			let newTime = event?.split(' ')
			if (newTime[1] == 'AM' || newTime[1] == 'PM') return event
			else {
				event = event?.includes('ص')
					? event?.replace('ص', 'AM')
					: event?.replace('م', 'PM')
				return event
			}
		} else return null
	}

	getFormArray() {
		return (this.workingHoursForm.get('workingHours') as FormArray)
			.controls as FormGroup[]
	}

	toggleChanged(form: FormGroup, i: number) {
		if (form?.get('isOn')?.value) {
			this.subscription.add(
				this.orgServ
					.CheckCanInactiveDay(this.branchId, form?.get('dayOfWeek')?.value)
					.subscribe({
						error: (error) => {
							form?.get('isOn')?.setValue(true)
							this.showError[i] = error?.error?.error?.message
						}
					})
			)
		}
	}

	validateWorkingHoures(form: FormGroup, i: number) {
		let body = {
			...form.getRawValue(),
			branchId: this.branchId
		}
		body.from = this.convertTime(body.from)
		body.to = this.convertTime(body.to)
		body.offTimeFrom ? body.offTimeFrom = this.convertTime(body.offTimeFrom) : null
		body.offTimeTo ? body.offTimeTo = this.convertTime(body.offTimeTo) : null
		this.subscription.add(
			this.orgServ
				.CheckWorkingHoursValidate(body)
				.subscribe({
					next: () => {
						this.showValidationError[i] = false
						this.showError[i] = ''
						this.inValid.emit(this.showValidationError.includes(true))
					},
					error: (error) => {
						this.showError[i] = error?.error?.error?.message
						this.showValidationError[i] = true
						this.inValid.emit(this.showValidationError.includes(true))
					}
				})
		)
	}

	ngOnDestroy() {
		this.subscription.unsubscribe()
	}
}
