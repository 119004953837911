<div class="flex justify-center items-center bg-white text-center h-[50vh]">
	<div>
		<img
			class="mb-12px lg:mt-0 mt-5 w-[40px] inline-block"
			src="./assets/images/{{ imageName }}"
			alt=""
		/>
		<p
			class="text-font-16 ltr:font-DIN-Bold font-bold rtl:font-medium text-dark-grey"
		>
			{{ text | translate }}
		</p>
		<button
			*ngIf="buttonTitle"
			class="bg-gradient items-center flex z-10 text-[#FCFCFC] rounded-[12px] m-[8px] px-24px py-12px"
			(click)="clickEvent.emit()"
			[style]="'width:' + buttonWidth + 'px'"
		>
			<span
				class="text-[14px] whitespace-nowrap"
				[style]="'width:' + (buttonWidth - 24) + 'px'"
				>+ {{ buttonTitle | translate }}</span
			>
		</button>
	</div>
</div>
