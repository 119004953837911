<div class="slider">
	<div *ngIf="title" class="col-form-label fw-bolder fs-6">
		{{ title | translate }}
	</div>
	<div class="range-slider">
		<span class="rangeValues">{{ from }} - {{ to }}</span>
		<input
			(change)="rangeChanged()"
			[(ngModel)]="from"
			[min]="minVal"
			[max]="maxVal"
			step="10"
			type="range"
		/>
		<input
			(change)="rangeChanged()"
			[(ngModel)]="to"
			[min]="minVal"
			[max]="maxVal"
			step="10"
			type="range"
		/>
	</div>
</div>
