<div class="flex p-3">
	<img
		class="mt-[5px] self-start cursor-pointer"
		[mat-dialog-close]="false"
		src="assets/images/backArrow.svg"
		alt="icon"
	/>
	<div class="grow">

	</div>
</div>
<image-cropper
	[imageChangedEvent]="data.imageChangedEvent"
	[maintainAspectRatio]="true"
	[aspectRatio]="data.imageUpload.imageCropDimensions"
	format="png"
	(imageCropped)="imageCropped($event)"
	[resizeToWidth]="data.imageUpload.maxWidth ? data.imageUpload.maxWidth : 500"
	class="overflow-y-scroll"
></image-cropper>
<div class="w-[88.5%] m-auto p-4">
	<button
		[mat-dialog-close]="croppedImage"
		class="w-100 bg-gradient text-white w-full rounded-[6px] py-[10px]"
	>
		{{ 'ACTIONS.CROP' | translate }}
	</button>
	<button
	[mat-dialog-close]="false"
	class="w-full rounded-6px border-[1px] border-secondry-color-Gray text-center py-[10px] mt-[10px] text-dark-grey"
>
	{{ 'ACTIONS.CANCEL' | translate }}
</button>
</div>
