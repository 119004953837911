import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { ApiEndPoint } from 'src/app/shared/constants/apis.constant'

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private http: HttpClient, private router: Router) {}

	login(body: any, lang?: string) {
		return this.http.post(`${ApiEndPoint.loginApi}?culture=${lang}`, body)
	}

	refreshToken(token: any) {
		return this.http.post(`${ApiEndPoint.refreshTokenApi}`, { token: token })
	}

	getToken() {
		return localStorage.getItem('authLocalStorageToken')
	}

	storeData(res: any) {
		localStorage.setItem('authLocalStorageToken', res.result.accessToken)
		localStorage.setItem('userId', res.result.userId)
		localStorage.setItem('RefreshToken', res.result.refreshToken)
		localStorage.setItem('fullName', JSON.stringify(res.result.fullName))
		localStorage.setItem(
			'profileImageUrl',
			JSON.stringify(res.result.profileImageUrl)
		)
		this.router.navigate(['./'])
	}

	logOut() {
		localStorage.removeItem('authLocalStorageToken')
		localStorage.removeItem('User')
		localStorage.removeItem('userTenantId')
		this.router.navigate(['/auth/login'])
	}
}
