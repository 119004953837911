<div
	class="max-w-[406px] flex justify-evenly items-center py-[8px] max-mobile:p-0 px-[10px] bg-off-white rounded-[50px] text-dark-grey mt-[18px] text-font-16 cursor-pointer border-secondry-color-Gray border-[1px]"
>
	<a
		(click)="countryChange(country.id)"
		*ngFor="let country of activeCountries"
		class="text-center w-[55%] max-mobile:m-[3px]"
		[ngClass]="{ activeClass: selectedCountry == country.id }"
	>
		<!-- prettier-ignore -->
		<img class="inline-block w-[24px] max-mobile:hidden max-mobile:w-full max-mobile:text-font-mobile-small" [src]="attachmentUrl + country.flagUrl" alt="" />
		{{
			this.translate.currentLang === 'ar'
				? country?.name?.ar
				: country?.name?.en
		}}
	</a>
</div>
