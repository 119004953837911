import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-no-data-found',
	templateUrl: './no-data-found.component.html',
	styleUrls: ['./no-data-found.component.scss'],
})
export class NoDataFoundComponent implements OnInit {
	@Input() imageName: string = 'search-no-data-found.svg'
	@Input() text: string = 'GENERAL.NO_DATA_FOUND'
	@Input() buttonTitle!: string
	@Input() buttonWidth: number = 188
	@Output() clickEvent: EventEmitter<void> = new EventEmitter()

	constructor() { }

	ngOnInit(): void { }
}
