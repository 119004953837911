import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { SharedService } from '../../services/shared.service'

@Component({
	selector: 'app-search-filter',
	templateUrl: './search-filter.component.html',
	styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
	@Input() showFilter: boolean = true
	@Input() showSearch: boolean = true
	@Input() filterCount: number = 0
	@Input() isRounded: boolean = false
	@Input() searchKey: string = 'GENERAL_LABELS.SEARCH'
	@Output() keyWord: EventEmitter<any> = new EventEmitter()
	@Output() toggleFilter: EventEmitter<any> = new EventEmitter()
	@Output() resetFilter: EventEmitter<string> = new EventEmitter()
	searchVal = new FormControl('')

	@Input() previousSearchText: string | null = ''
	searchText: any
	constructor(private sharedServ: SharedService) {}

	ngOnInit(): void {}
	ngOnChanges() {
		this.previousSearchText
			? this.searchVal.setValue(this.previousSearchText)
			: null
	}
	inputChange(event: any) {
		let SearchKeyword = event.target.value

		SearchKeyword.length == 0 ? this.keyWord.emit(this.searchVal.value) : ''
	}
	clearSearch() {
		this.searchVal.setValue('')
		this.keyWord.emit('')
	}
}
