import { LOCALE_ID, NgModule } from '@angular/core'
import { CommonModule, registerLocaleData } from '@angular/common'
import { MaterialsModule } from './materials.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { LanguageComponent } from './components/language/language.component'
import { ConfirmationMessageComponent } from './components/confirmation-message/confirmation-message'
import { HttpClientModule } from '@angular/common/http'
import { ToastrModule } from 'ngx-toastr'
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading'
import { ValidationMessageComponent } from './components/validation-message/validation-message.component'
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component'
import { AutoFocusDirective } from './directives/auto-focus.directive'
import { SearchFilterComponent } from './components/search-filter/search-filter.component'
import { UserCardComponent } from './components/user-card/user-card.component'
import { CustomStepperComponent } from './components/custom-stepper/custom-stepper.component'
import { FixedButtonComponent } from './components/fixed-button/fixed-button.component'
import { StatusComponent } from './components/status/status.component'
import { NgxPaginationModule } from 'ngx-pagination'
import { TableComponent } from './components/table/table.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { FilterComponent } from './components/filter/filter.component'
import { UploadImageComponent } from './components/upload-image/upload-image.component'
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker.component'
import { CustomMatCalendarComponent } from './components/custom-mat-calendar/custom-mat-calendar.component'
import { ImageCropperModule } from 'ngx-image-cropper'
import { CarouselComponent } from './components/carousel/carousel.component'
import { CarouselModule } from 'ngx-owl-carousel-o'
import { WorkingHoursComponent } from './components/working-hours/working-hours.component'
import { NoDataFoundComponent } from './no-data-found/no-data-found.component'
import { ImageCropperComponent } from './components/upload-image/image-cropper/image-cropper.component'
import { MobileNumberComponent } from './components/mobile-number/mobile-number.component'
import { CustomCurrencyPipe } from './pipes/currency.pipe'
import { CustomDurationPipe } from './pipes/duration.pipe'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import localeAr from '@angular/common/locales/ar'
import localeEn from '@angular/common/locales/en'
import { PaginationComponent } from './components/pagination/pagination.component'
import { WorkingHoursViewModeComponent } from './components/working-hours-view-mode/working-hours-view-mode.component'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { PricingSliderComponent } from './components/pricing-slider/pricing-slider.component'
import { RatingStarsPipe } from './pipes/date-format.pipe'
import { NgxPrettyDateModule } from 'ngx-pretty-date'
import { TimeAgoPipe } from './pipes/hande-date.pipe'
import { MatIconModule } from '@angular/material/icon'
import { CountriesTabsComponent } from './components/countries-tabs/countries-tabs.component'
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MatMomentDateModule,
} from '@angular/material-moment-adapter'

registerLocaleData(localeAr, 'ar')
registerLocaleData(localeEn, 'en')
@NgModule({
	declarations: [
		LanguageComponent,
		ConfirmationMessageComponent,
		ValidationMessageComponent,
		TermsConditionsComponent,
		AutoFocusDirective,
		SearchFilterComponent,
		UserCardComponent,
		CustomStepperComponent,
		FixedButtonComponent,
		StatusComponent,
		TableComponent,
		CustomDatepickerComponent,
		CustomMatCalendarComponent,
		TabsComponent,
		FilterComponent,
		UploadImageComponent,
		WorkingHoursComponent,
		CarouselComponent,
		NoDataFoundComponent,
		ImageCropperComponent,
		MobileNumberComponent,
		CustomCurrencyPipe,
		CustomDurationPipe,
		PaginationComponent,
		WorkingHoursViewModeComponent,
		PricingSliderComponent,
		RatingStarsPipe,
		TimeAgoPipe,
		CountriesTabsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		MatIconModule,
		NgxPaginationModule,
		ImageCropperModule,
		ReactiveFormsModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-left',
		}),
		HttpClientModule,
		TranslateModule,
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.circleSwish,
			backdropBackgroundColour: 'rgba(0,0,0,0.1)',
			backdropBorderRadius: '4px',
			primaryColour: '#ffffff',
			secondaryColour: '#ffffff',
			tertiaryColour: '#ffffff',
			fullScreenBackdrop: true,
		}),
		CarouselModule,
		NgxPaginationModule,
		InfiniteScrollModule,
		MaterialsModule,
		MatMomentDateModule,
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		MaterialsModule,
		TranslateModule,
		LanguageComponent,
		HttpClientModule,
		NgxPaginationModule,
		ToastrModule,
		NgxLoadingModule,
		TermsConditionsComponent,
		ValidationMessageComponent,
		AutoFocusDirective,
		SearchFilterComponent,
		UserCardComponent,
		CustomStepperComponent,
		FixedButtonComponent,
		StatusComponent,
		TableComponent,
		CustomDatepickerComponent,
		CustomMatCalendarComponent,
		TabsComponent,
		FilterComponent,
		UploadImageComponent,
		WorkingHoursComponent,
		CarouselModule,
		CarouselComponent,
		NoDataFoundComponent,
		MobileNumberComponent,
		CustomCurrencyPipe,
		CustomDurationPipe,
		PaginationComponent,
		ImageCropperModule,
		InfiniteScrollModule,
		PricingSliderComponent,
		RatingStarsPipe,
		NgxPrettyDateModule,
		TimeAgoPipe,
		CountriesTabsComponent,
	],
	providers: [
		{
			provide: LOCALE_ID,
			useValue: localStorage.getItem('language') == 'ar' ? 'ar-IN' : 'en-en-US',
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: localStorage.getItem('language') == 'ar' ? 'ar-IN' : 'en-GB',
		},
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
	],
})
export class SharedModule {}
