import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { organizationFilter, organizationsList } from '../models/organizations.model'
import { ApiEndPoint } from 'src/app/shared/constants/apis.constant'
import { BehaviorSubject, map } from 'rxjs'
import {
  BranchInformationModel,
  UpdateBasicInfoModel,
  UpdateLocationModel,
  UpdateSocialAccModel,
  UpdateTargetAudienceModel,
} from '../models/branch-information.model'
import { PendingRejectedActions } from 'src/app/modules/organization/models/pending-rejected-actions.model'
import { Countries, workingHour } from 'src/app/shared/models/general.models'
import { OrganzationProfile } from 'src/app/modules/organization/models/organzation-profile.model'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
	providedIn: 'root',
})
export class OrganizationsService {
	constructor(
		private http: HttpClient,
		private router: Router,
		private translate: TranslateService
	) {}
	lang = this.translate.currentLang
	activeOrganization : BehaviorSubject<any>  = new BehaviorSubject('')
	getOrganizationsList(filter: organizationFilter) {
		return this.http.post(ApiEndPoint.organizationListApi, filter)
	}

	getOrganizationBranchInformation(id: number) {
		return this.http
			.get(ApiEndPoint.organizationBranchInformationApi + `?id=${id}`)
			.pipe(
				map((response: any) => {
					return response.result as BranchInformationModel
				})
			)
	}
	pendingRejectedProfile(id: string | null) {
		return this.http
			.get(`${ApiEndPoint.pendingRejectedProfileApi}?id=${id}`)
			.pipe(
				map((response: any) => {
					return response.result as PendingRejectedActions
				})
			)
	}

	acceptOrganization(id: number | null) {
		return this.http.post(`${ApiEndPoint.acceptOrganizationApi}?id=${id}`, {})
	}

	rejectOrganization(id: number | null) {
		return this.http.post(`${ApiEndPoint.rejectOrganizationApi}?id=${id}`, {})
	}

	activateDeactivateBranch(id: number, isActive: boolean) {
		return this.http.put(
			`${ApiEndPoint.Activate_Deactivate_OrganizationBranchApi}`,
			{ id, isActive }
		)
	}

	getBusinessDetails(id: number) {
		return this.http
			.get(`${ApiEndPoint.getBusinessDetailsApi}?id=${id}`)
			.pipe(map((result: any) => result.result as organizationsList))
	}

	updateBranchLocation(updateLocationModel: UpdateLocationModel) {
		return this.http.put(
			ApiEndPoint.updateBranchLocationApi,
			updateLocationModel
		)
	}

	updateSocialAccounts(updateSocialAccounts: UpdateSocialAccModel) {
		return this.http.put(
			ApiEndPoint.updateSocialAccountsApi,
			updateSocialAccounts
		)
	}
	updateWorkingHours(UpdateWorkingHours: workingHour) {
		return this.http.put(ApiEndPoint.updateWorkingHoursApi, UpdateWorkingHours)
	}
	updateTargetAudience(updateTargetAudienceModel: UpdateTargetAudienceModel) {
		return this.http.put(
			ApiEndPoint.updateTargetAudienceApi,
			updateTargetAudienceModel
		)
	}

	updateBranchBasicInformation(updateBasicInfoModel: UpdateBasicInfoModel) {
		return this.http.put(
			`${ApiEndPoint.updateBranchBasicInformationApi}?culture=${this.lang}`,
			updateBasicInfoModel
		)
	}
	updateBranchContactInformation(updateBasicInfoModel: any) {
		return this.http.put(
			`${ApiEndPoint.updateBranchContactInformationApi}?culture=${this.lang}`,
			updateBasicInfoModel
		)
	}
	UpdateBusinessDetails(body: any) {
		return this.http.put(ApiEndPoint.UpdateBusinessDetailsApi, body)
	}

	checkBussinessName(id: any, name: string) {
		return this.http.post(
			`${ApiEndPoint.checkBussinessNameApi}?id=${id}&businessName=${name}`,
			{}
		)
	}

	uploadOrganizationLogo(body: any) {
		return this.http.post(ApiEndPoint.UploadLogoImageApi, body)
	}
	UploadCommercialRegistrationImage(body: any) {
		return this.http.post(
			ApiEndPoint.UploadCommercialRegistrationImageApi,
			body
		)
	}
	UploadMaroofImage(body: any) {
		return this.http.post(ApiEndPoint.UploadMaroofImageApi, body)
	}

	getOrganizationProfile(id: string) {
		return this.http
			.get(`${ApiEndPoint.organizationProfileApi}?id=${id}`)
			.pipe(map((result: any) => result.result as OrganzationProfile))
	}
	UploadSliderImage(body: any) {
		return this.http.post(ApiEndPoint.UploadSliderImageApi, body)
	}
	updateSliderImage(body: any) {
		return this.http.post(ApiEndPoint.updateSliderImageApi, body)
	}

	updateBranchSettings(body: any) {
		return this.http.put(`${ApiEndPoint.saveBranchSettingsApi}`, body)
	}
	getActiveCountries() {
		return this.http.get(`${ApiEndPoint.getCountryCodeApi}?IsActive=true`).pipe(
			map((response: any) => {
				return response.result as Countries
			})
		)
	}
	getServiceProvidersCount(body: any) {
		return this.http.post(`${ApiEndPoint.serviceProversCountApi}`, body).pipe(
			map((response: any) => {
				return response.result
			})
		)
	}
	GetCityByCountry(countryId: any) {
		return this.http.get(
			`${ApiEndPoint.GetCityByCountry}?countryId=${countryId}`
		)
	}

	BranchValidateUniqueName(body: any) {
		return this.http.post(
			`${ApiEndPoint.BranchValidateUniqueNameApi}?culture=${this.lang}`,
			body
		)
	}
	BranchValidateUniqueContact(body: any) {
		return this.http.post(
			`${ApiEndPoint.BranchValidateUniqueContactInfoApi}?culture=${this.lang}`,
			body
		)
	}
	GetIsNameUnique(body: any) {
		return this.http.post(`${ApiEndPoint.GetIsNameUnique}`, body)
	}
	getIsMobileUnique(body: any) {
		return this.http.post(`${ApiEndPoint.IsMobileNumberUnique}`, body)
	}
	getIsLandLineUnique(body: any) {
		return this.http.post(`${ApiEndPoint.IsLandLineUnique}`, body)
	}
	CheckCanInactiveDay(branchId: number, dayOfWeek: number) {
		return this.http.get(
			`${ApiEndPoint.CheckCanInactiveDayApi}?branchId=${branchId}&dayOfWeek=${dayOfWeek}`
		)
	}
	CheckWorkingHoursValidate(body: any) {
		return this.http.post(`${ApiEndPoint.validateWorkingHoursApi}`, body)
	}

	// gallery
	uploadGalleryVideo(body: any) {
		return this.http.post(`${ApiEndPoint.UploadGalleryVideoApi}`, body)
	}
	uploadGalleryVideoThumb(body: any) {
		return this.http.post(`${ApiEndPoint.UploadVideoThumbnailApi}`, body)
	}
	uploadGalleryImage(body: any) {
		return this.http.post(`${ApiEndPoint.UploadGlleyImage}`, body)
	}
	getGallery(body: any) {
		return this.http.post(`${ApiEndPoint.BranchGalleryApi}`, body)
	}
	AddToGallery(body: any) {
		return this.http.post(`${ApiEndPoint.AddToGalleryApi}`, body)
	}
	DeleteFromGallery(id: number) {
		return this.http.delete(`${ApiEndPoint.DeleteGalleryItem}?id=${id}`)
	}
	SetSlider(id: any) {
		return this.http.post(`${ApiEndPoint.SetSliderApi}?id=${id}`, '')
	}
}
