import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
	selector: 'app-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
	@Input() taps: any[] = []
	@Input() prefix: string = 'STATUS.'
	@Input() activeTap: number | string = 1
	@Output() tapChanged: EventEmitter<number> = new EventEmitter()
	@Input() count: boolean = false
	@Input() padding: number = 18
	@Input() countNum = ''
	constructor(public translate: TranslateService) {}

	ngOnInit(): void {}
}
