import { Injectable } from '@angular/core'
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http'

import { BehaviorSubject, Observable, throwError } from 'rxjs'
import {
	catchError,
	filter,
	finalize,
	map,
	switchMap,
	take,
} from 'rxjs/operators'
import { Router } from '@angular/router' // Import Router
import { ToastrService } from 'ngx-toastr'
import { AuthService } from 'src/app/modules/auth/services/auth.service'
import { LoadingService } from '../../shared/services/loading.service'
import { environment } from 'src/environments/environment'

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	requestCount = 0
	isRefreshing = false
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
		null
	)

	constructor(
		private toast: ToastrService,
		private auth: AuthService,
		private loadingServ: LoadingService,
		private router: Router // Inject Router service
	) {}

	intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!request.url.includes('assets')) {
			this.loadingServ.showSpinner()
			this.requestCount++

			let token = this.auth.getToken()

			if (request.headers.get('Anonymous') == 'custom') {
				const newHeaders = request.headers.delete('Anonymous')
				const newRequest = request.clone({ headers: newHeaders })
				return next.handle(newRequest).pipe(
					map((event: HttpEvent<any>) => {
						return event
					}),
					catchError((error: HttpErrorResponse) => {
						if (error?.status === 401) this.auth.logOut()
						else if (error?.error?.error?.message)
							this.toast.error(error?.error?.error?.message)
						return throwError(() => error)
					}),
					finalize(() => {
						this.requestCount--
						this.requestCount == 0 ? this.loadingServ.hideSpinner() : null
					})
				)
			} else {
				request = this.handleRequest(request, token)
				return next.handle(request).pipe(
					map((event: HttpEvent<any>) => {
						return event
					}),
					catchError((error: any) => {
						if (error?.status === 401) {
							if (!this.isRefreshing)
								return this.handleTokenError(request, next)
							else {
								return this.refreshTokenSubject.pipe(
									filter((result) => result !== null),
									take(1),
									switchMap((accessToken) => {
										return next.handle(this.handleRequest(request, accessToken))
									})
								)
							}
						} else if (error?.error?.error?.message)
							this.toast.error(error?.error?.error?.message)
						return throwError(() => error)
					}),
					finalize(() => {
						this.requestCount--
						this.requestCount == 0 ? this.loadingServ.hideSpinner() : null
					})
				)
			}
		} else {
			return next.handle(request).pipe(
				map((event: HttpEvent<any>) => {
					return event
				})
			)
		}
	}

	handleRequest(request: any, token: any) {
		// prettier-ignore
		const locationLink = this.router.url
		let headers: HttpHeaders = new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			locationLink: locationLink,
			role: 'admin',
			...(!request.url.includes('Image') ||
				(request.url.includes('video') && {
					'Content-Type': 'application/json',
				})),
			...(token && { Authorization: `Bearer ${token}` }),
		})
		this.loadingServ.showSpinner()
		return request.clone({
			headers: headers,
			url: !request?.url.includes('https')
				? `${environment.apiUrl}/${request.url}`
				: request?.url,
		})
	}

	handleTokenError(req: HttpRequest<any>, next: HttpHandler) {
		let refreshToken = localStorage.getItem('RefreshToken')
		this.isRefreshing = true
		this.refreshTokenSubject.next(null)
		return this.auth.refreshToken(refreshToken).pipe(
			switchMap((data: any) => {
				this.auth.storeData(data)
				this.isRefreshing = false
				this.refreshTokenSubject.next(data.result.accessToken)
				return next.handle(this.handleRequest(req, data.result.accessToken))
			}),
			catchError((err: any) => {
				return throwError(() => {
					this.isRefreshing = false
					this.auth.logOut()
				})
			})
		)
	}
}
