import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { confirmModel } from '../../models/general.models'
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
	selector: 'confirmation-message',
	templateUrl: './confirmation-message.html',
})
export class ConfirmationMessageComponent implements OnInit {
	formData: FormGroup = new FormGroup({})
	formControl: any

	constructor(
		public dialog: MatDialogRef<ConfirmationMessageComponent>,
		@Inject(MAT_DIALOG_DATA) public data: confirmModel
	) {
		if (data.hasInput) {
			this.formData = new FormGroup({
				formControl: new FormControl('', [Validators.required]),
			})
		}
	}

	ngOnInit() {}

	confirm() {
		let formdata = {
			msg: this.formControl,
		}
		this.dialog.close(this.data?.hasInput ? formdata : true)
	}

	closeDialog() {
		this.dialog.close()
	}

	getController(controlName: any) {
		return this.formData.controls[controlName]
	}
}
