<div
	class="whitespace-nowrap text-font-12"
	*ngIf="controller?.hasError('required') && controller?.touched"
>
	{{
		'VALIDATION.REQUIRED' | translate : { name: (controller_name | translate) }
	}}
</div>

<div
	class="whitespace-nowrap text-font-12"
	*ngIf="controller?.hasError('maxlength') && controller?.touched"
>
	{{
		'VALIDATION.MAX_LENGTH'
			| translate
				: {
						number: controller.errors.maxlength.requiredLength,
						name: (controller_name | translate)
				  }
	}}
</div>

<div
	class="whitespace-nowrap text-font-12"
	*ngIf="controller?.hasError('minlength') && controller?.touched"
>
	{{
		(isNumber ? 'VALIDATION.MIN_LENGTH_NUMBERS' : 'VALIDATION.MIN_LENGTH')
			| translate
				: {
						number: controller?.errors?.minlength?.requiredLength,
						name: (controller_name | translate)
				  }
	}}
</div>

<div
	class="whitespace-nowrap text-font-12"
	*ngIf="
		controller?.hasError('pattern') &&
		!controller?.hasError('minlength') &&
		controller?.touched
	"
>
	{{ pattern_Message | translate }}
</div>

<div
	class="whitespace-nowrap text-font-12"
	*ngIf="controller?.hasError('email') && controller?.touched"
>
	{{ 'VALIDATION.EMAIL' | translate }}
</div>

<div
	class="whitespace-nowrap text-font-12"
	*ngIf="
		controller?.hasError('confirmPassword') &&
		!controller?.hasError('minlength') &&
		!controller?.hasError('required') &&
		controller?.touched
	"
>
	{{ 'VALIDATION.CONFIRM_PASSWORD' | translate }}
</div>

<div
	class="whitespace-nowrap"
	*ngIf="controller?.hasError('min') && controller?.touched"
>
	{{
		'VALIDATION.MIN_NO'
			| translate : { number: controller?.errors?.min?.min - 1 }
	}}
</div>

<div
	class="whitespace-nowrap"
	*ngIf="controller?.hasError('max') && controller?.touched"
>
	{{ 'VALIDATION.MAX' | translate : { number: controller?.errors?.max?.max } }}
</div>
