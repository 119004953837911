<div *ngFor="let time of workingHours; let i = index">
	<h1
		class="text-medium-gray text-font-16  leading-[24px] mb-[6px] {{
			time?.isOn ? '' : 'opacity-30'
		}}"
	>
		{{ 'DAYS.' + weekDays[i].name.toUpperCase() | translate }}
	</h1>
	<div
		class="flex justify-between border-2 border-solid rounded-[6px] md:px-[10px] px-[4px] py-[10px] mb-[8px]"
	>
		<div class="flex">
			<ngx-material-timepicker-toggle
				[disabled]="!editMode"
				[ngClass]="!editMode ? 'fill-[#6E7191]' : 'fill-[#4E4B66]'"
				class="{{ time?.isOn ? '' : 'opacity-30' }}"
			></ngx-material-timepicker-toggle>

			<span
				class="focus:outline-none bg-off-white md:text-font-16 text-font-14  text-dark-grey mt-2  {{
					time?.isOn ? '' : 'opacity-30'
				}}"
				>{{ time?.from }}</span
			>
			<p
				class="text-[#4E4B66] h-[24px] text-font-16 mx-[8px] mb-0 mt-[5px] {{
					time?.isOn ? '' : 'opacity-30'
				}}"
			>
				|
			</p>
			<span
				class="focus:outline-none bg-off-white md:text-font-16 text-font-14  text-dark-grey mt-2 {{
					time?.isOn ? '' : 'opacity-30'
				}}"
			>
				{{ time?.to }}</span
			>
		</div>

		<app-status
			[enumData]="WorkingHoursStatus"
			[statusValue]="time?.isOn"
			class="mt-1 mx-2"
		></app-status>
	</div>
</div>
