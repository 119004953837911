<div class="w-[88.5%] m-auto p-5">
  <div (click)="closeDialog()">
    <img
      class="ml-[102%] cursor-pointer"
      src="assets/images/close-square.svg"
      alt=""
    />
  </div>

  <div
    *ngIf="!croppedImage"
    class="text-center rounded-[6px] border-[#D9DBE9] border pb-[16px] mb-[18px]"
  >
    <button class="mt-[18px] mb-12px">
      <label class="custom-file-upload cursor-pointer">
        <input
          #fileInput
          class="cursor-pointer"
          type="file"
          (change)="fileChangeEvent($event)"
        />
        <img class="m-auto" src="./assets/images/upload.svg" alt="" />
        <h1 class="text-dark-grey text-font-16 font-normal leading-[24px] m-0">
          {{ 'ACTIONS.UPLOAD_IMG' | translate }}
        </h1>
      </label>
    </button>
  </div>
  <div class="relative text-center" *ngIf="croppedImage">
		<span
      class="absolute top-3 rtl:left-3 ltr:right-3 cursor-pointer"
      (click)="deleteImage()"
    >
			<a
        matSuffix
        class="w-[21px] h-[21px] rounded-full border-2 border-solid border-off-white text-off-white mb-[18px] flex justify-center"
      >
				<mat-icon class="text-xs font-bold mt-[1px]">close</mat-icon>
			</a>
			<img src="" alt="" />
		</span>
    <img class="w-full rounded-[12px] bg-contain" [src]="croppedImage" alt="" />
  </div>
  <p *ngIf="!sizeError" class="text-font-14 my-[18px] text-medium-gray">
    {{
    'GENERAL_LABELS.IMAGE_HINT'
      | translate
      : { height: imageUpload.imageHeight, width: imageUpload.imageWidth }
    }}
  </p>
  <small *ngIf="sizeError" class="text-red-error">
    {{ 'ERROR_MESSAGES.IMAGE_SIZE' | translate }}
  </small>
  <button
    mat-button
    [disabled]="!croppedImage"
    [mat-dialog-close]="{
			selectedImage: croppedImage,
			selectedFile: uploadedFile
		}"
    class="w-100 bg-gradient text-white w-full rounded-[6px]"
  >
    {{ 'ACTIONS.UPLOAD_IMG' | translate }}
  </button>
</div>
